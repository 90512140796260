























































import { CDrawer } from "@/models/utils/c-drawer.interace";
import { Component, PropSync, Vue } from "vue-property-decorator";

const CLocationInfo = () =>
  import(
    /* webpackChunkName: "CLocationInfo" */ "./left-components/CLocationInfo.vue"
  );
const CJobs = () =>
  import(/* webpackChunkName: "CJobs" */ "./left-components/CJobs.vue");
const CEmployees = () =>
  import(
    /* webpackChunkName: "CEmployees" */ "./left-components/CEmployees.vue"
  );
const CEmployeeInfo = () =>
  import(
    /* webpackChunkName: "CEmployeeInfo" */ "./right-components/CEmployeeInfo.vue"
  );
const CChartArea = () =>
  import(
    /* webpackChunkName: "CChartArea" */ "./right-components/Chart/CChartArea.vue"
  );

@Component({
  components: {
    "c-location-info": CLocationInfo,
    "c-jobs": CJobs,
    "c-employees": CEmployees,
    "c-employee-info": CEmployeeInfo,
    "c-chart-area": CChartArea,
  },
})
export default class CDrawerBody extends Vue {
  @PropSync("drawerItem", { required: true, default: null, type: Object })
  private _drawerItem: CDrawer;
}
